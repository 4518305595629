import { render, staticRenderFns } from "./FriendsPage.vue?vue&type=template&id=5e34d07e&scoped=true"
import script from "./FriendsPage.vue?vue&type=script&lang=js"
export * from "./FriendsPage.vue?vue&type=script&lang=js"
import style0 from "./FriendsPage.vue?vue&type=style&index=0&id=5e34d07e&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5e34d07e",
  null
  
)

export default component.exports