<template>
    <div class="friend-bar">
        <div class="friend-bar_details">
            <div class="friend-bar_details__item">
                <div class="friend-bar_details__item-text">
                    <PresetLoader v-if="loading" />
                    <span
                        v-else
                        class="number earnings"
                    >{{ counters.friends }}</span>
                    <span class="text">Friends</span>
                </div>
            </div>
            <div class="friend-bar_details__item ">
                <!--                <writing />-->
                <div class="friend-bar_details__item-text">
                    <PresetLoader v-if="loading" />
                    <span
                        v-else
                        class="number"
                    >{{ counters.orders }}</span>
                    <span class="text">Orders</span>
                </div>
            </div>
            <div class="friend-bar_details__item">
                <!--                <walet />-->
                <div class="friend-bar_details__item-text">
                    <PresetLoader v-if="loading" />
                    <span
                        v-else
                        class="number earnings"
                    >{{ counters.earnings | money }}</span>
                    <span class="text">Earnings</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import Writing from '@/components/icons/Writing.vue';
// import Walet from '@/components/icons/Walet.vue';
import PresetLoader from '@/components/common/PresetLoader';
import filtersMixin from '@/mixins/filters-mixin.js'

export default {
    components: {
        // Writing,
        // Walet,
        PresetLoader
    },
    mixins: [
        filtersMixin
    ],
    props: {
        loading: {
            type: Boolean
        },
        counters: {
            type: Object
        }
    }
}
</script>

<style lang="scss">
    .friend-bar{
        display: flex;
        justify-content: space-between;
        &_details{
            display: flex;
            justify-content: flex-end;
            &__item{
                display: flex;
                align-items: center;
                margin-right: 50px;
                &-text{
                    color: #1f2939;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    .number{
                        font-size: 24px;
                        font-weight: 700;
                        margin-bottom: 4px;
                        &.earnings{
                            color: #77c93a;
                        }
                    }
                    .text{
                        color: #1f2939;
                        font-size: 18px;
                        font-weight: 400;
                    }
                }
            }
        }
        @media all and (max-width: 576px) {
            flex-direction: column;
            &_btns{
                width: 100%;
                .btn-base {
                    width: 100%;
                }
            }
            &_details{
                margin-top: 15px;
                justify-content: center;
            }
        }
    }
</style>
